import Image from 'next/image';
import { FC, memo } from 'react';

interface IProps {
    desc?: string;
    title?: string;
    imageName?: string;
    classTitle?: string;
    classContent?: string;
}

const MoreDetailItem: FC<IProps> = ({
    desc = '',
    title = '',
    imageName = '',
    classTitle = '',
    classContent = 'md:px-3',
}) => {
    return (
        <div className="flex flex-col items-center justify-start">
            <Image
                width={100}
                height={79}
                src={`/images/home/more-detail-item-${imageName}.svg`}
                alt="grow subscriber icon"
            />
            <div
                className={`text-violet-blackcurrant font-medium text-xl font-Circular-Std mt-18px md:mt-35px mb-5 md:mb-6 ${classTitle}`}
            >
                {title || 'Grow Subscribers'}
            </div>
            <p
                className={`text-grey-mid-gray text-center leading-30px font-Inter ${classContent} text-15px`}
            >
                {desc ||
                    'Is your main goal to increase subscribers quickly? At Tikfamecontentboost we’ve got just the right solutions for you. Select one of our packages and you will see subscribers starting to increase within 24 hours.'}
            </p>
        </div>
    );
};

export default memo<IProps>(MoreDetailItem);
